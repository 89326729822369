<template>
  <div>
    <b-modal
      ref="stockslip-history"
      hide-footer
      title="Lịch sử chỉnh sửa"
      size="xl"
    >
      <b-row>
        <b-col md="3">
          <label class="lable-sp">Chỉnh sửa bởi: </label>
        </b-col>
        <b-col md="8" v-if="history.createdByUser">
          <label>{{ history.createdByUser.fullName }}</label>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col md="3">
          <label class="lable-sp">Chỉnh sửa lúc: </label>
        </b-col>
        <b-col md="9">
          <label>{{ formatDate(history.createdAt, CLIENT_DATE_TIME()) }}</label>
        </b-col>
      </b-row>
      <h5 class="bold-text">Chi tiết thay đổi thông tin</h5>
      <hr />
      <b-table
        bordered
        hover
        :fields="fields"
        :items="diffValues"
        class="col-md-8"
      >
        <template v-slot:cell(originalValue)="row">
          {{row.item}}
          <div style="text-align: end" v-if="row.item.isNumber">
            <span v-text="convertPrice(row.item.originalValue)"></span>
          </div>
          <span v-else v-text="row.item.originalValue"></span>
        </template>
        <template v-slot:cell(newValue)="row">
          {{row.item}}
          <div style="text-align: end" v-if="row.item.isNumber">
            <span v-text="convertPrice(row.item.newValue)"></span>
          </div>
          <span v-else v-text="row.item.newValue"></span>
        </template>
      </b-table>
      <h5 class="bold-text">Chi tiết thay đổi sản phẩm</h5>
      <hr />
      <table
        class="table table-bordered table-vertical-center table-hover mb-10"
      >
        <tr>
          <th style="width: 50%">Trước</th>
          <th>Sau</th>
        </tr>
        <tr v-for="(item, index) in history.historyItems" :key="index">
          <td>
            <b-row v-show="item.originalItem.productName">
              <b-col class="col col-sm-3">
                <p class="header-color">Sản phẩm:</p>
                <p class="header-color">Số khung:</p>
                <p class="header-color">Số máy:</p>
                <p class="header-color">Số lượng:</p>
                <p class="header-color">Đơn giá:</p>
                <p class="header-color">Chiết khấu:</p>
                <p class="header-color">Thành tiền:</p>
              </b-col>
              <b-col>
                <p v-html="item.originalItem.productName || '<br/>'"></p>
                <p v-html="item.originalItem.chassisNumber || '<br/>'"></p>
                <p v-html="item.originalItem.engineNumber || '<br/>'"></p>
                <p>
                  {{ convertPrice(item.originalItem.quantity) }}
                </p>
                <p>{{ convertPrice(item.originalItem.unitPrice) }}</p>
                <p>{{ convertPrice(item.originalItem.discountAmount) }} {{}}</p>
                <p>{{ convertPrice(item.originalItem.totalAmount) }}</p>
              </b-col>
            </b-row>
          </td>
          <td>
            <b-row v-show="item.newItem.productName">
              <b-col class="col col-sm-3">
                <p class="header-color">Sản phẩm:</p>
                <p class="header-color">Số khung:</p>
                <p class="header-color">Số máy:</p>
                <p class="header-color">Số lượng:</p>
                <p class="header-color">Đơn giá:</p>
                <p class="header-color">Chiết khấu:</p>
                <p class="header-color">Thành tiền:</p>
              </b-col>
              <b-col>
                <p v-html="item.newItem.productName || '<br/>'"></p>
                <p v-html="item.newItem.chassisNumber || '<br/>'"></p>
                <p v-html="item.newItem.engineNumber || '<br/>'"></p>
                <p>
                  {{ convertPrice(item.newItem.quantity) }}
                </p>
                <p>{{ convertPrice(item.newItem.unitPrice) }}</p>
                <p>{{ convertPrice(item.newItem.discountAmount) }}</p>
                <p>{{ convertPrice(item.newItem.totalAmount) }}</p>
              </b-col>
            </b-row>
          </td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import { convertPrice, getDiffs, omitId } from '@/utils/common';
import { CLIENT_DATE_TIME, formatDate } from '@/utils/date';

export default {
  data() {
    return {
      history: {
        createdBy: '',
        createdAt: '',
        diffValues: [],
        historyItems: [],
        newValue: {},
        originValue: {},
      },
      fields: [
        {
          key: 'keyName',
          label: 'Thông tin',
          thStyle: { textAlign: 'center', width: '20%' },
          tdClass: 'text-left',
        },
        {
          key: 'originalValue',
          label: 'Trước',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '40%',
          },
        },
        {
          key: 'newValue',
          label: 'Sau',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '40%',
          },
        },
      ],
      onLoading: false,
    };
  },
  methods: {
    CLIENT_DATE_TIME() {
      return CLIENT_DATE_TIME;
    },
    formatDate,
    showModal: function(historyItem) {
      this.history = historyItem;
      const { rest: newRest } = omitId(JSON.parse(historyItem.newValue));
      const { rest: originRest } = omitId(JSON.parse(historyItem.originValue));
      this.diffValues = getDiffs(originRest, newRest);
      console.log('--> diffValues', this.diffValues);
      // console.log('--> newValue', this.newValue);
      // console.log('--> originValue', this.originValue);
      // this.diffValues = getDiffs(historyItem.newValue, history.originalValue)
      this.$nextTick(() => {
        this.$refs['stockslip-history'].show();
      });
    },
    convertPrice(value) {
      return convertPrice(value);
    },
  },
};
</script>

<style scoped>
.lable-sp {
  font-weight: 500;
}

.header-color {
  font-weight: 450;
}
</style>
